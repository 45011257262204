import React, { FC, useCallback, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useStore } from 'easy-peasy';
import PhyhubDevicesContainer from '../../../../common/phyhub-devices/phyhub-devices.container';
import usePhyhubDeviceFilters from '../../../../common/phyhub-devices/phyhub-devices-list/phyhub-devices-search-and-sort/hooks/use-phyhub-device-filters';
import usePhyhubDeviceSortOptions from '../../../../common/phyhub-devices/phyhub-devices-list/phyhub-devices-search-and-sort/hooks/use-phyhub-device-sort-options';
import { RootState } from '../../../../../store/models/root.model';
import OrganisationApp from '../../../../../store/types/organisation-app';
import { PhyhubDeviceListParamsFiltersOperatorEnum } from '../../../../../services/phyhub/types/phyhub-device-list-params.interface';
import useEnvironments from '../../../../common/use-environments/use-environments';
import { useSpaces } from '../../../../common/use-spaces';
import ConnectSetupPhyhubDeviceModal from './connect-setup-phyhub-device-modal/connect-setup-phyhub-device-modal.component';
import usePhyhubDeviceDisconnectFlow from '../../../../common/phyhub-devices/hooks/phyhub-device-actions/use-phyhub-device-disconnect-flow';
import { PhyhubDevicesSettingsAction } from '../../../../common/phyhub-devices/phyhub-devices-list/phyhub-devices-table/phyhub-devices-table-settings-cell/phyhub-devices-table-settings-actions/phyhub-devices-table-settings-actions.component';
import { PhyhubDevice } from '../../../../../services/phyhub/types/phyhub-device.interface';
import usePhyhubDevicesDefaultMassActions from '../../../../common/phyhub-devices/hooks/use-phyhub-devices-default-mass-actions';
import usePhyhubDevicesSearchAndFilters from '../../../../common/phyhub-devices/hooks/use-phyhub-devices-search-and-filters';
import PhyhubDevicesOverviewContainer from '../../../../common/phyhub-devices/phyhub-devices-list/phyhub-devices-summary/phyhub-devices-overview.container';
import usePhyhubDevicesDefaultDeviceActions from '../../../../common/phyhub-devices/hooks/use-phyhub-devices-default-device-actions';
import usePhyhubDevicesDefaultTableColumns from '../../../../common/phyhub-devices/hooks/use-phyhub-devices-default-table-columns';

interface AppPhyhubDevicesContainerProps
  extends RouteComponentProps<{ organisationId: string; appId: string }> {}

const InstallationPhyhubDevicesContainer: FC<AppPhyhubDevicesContainerProps> = (
  props,
) => {
  const {
    match: {
      params: { appId, organisationId: tenantId },
    },
  } = props;

  const { t } = useTranslation();

  const { data: environmentsCollection = [] } = useEnvironments(tenantId);

  const { data: spacesResponse } = useSpaces({ organisationId: tenantId });

  const [isConnectOrSetupModalVisible, setIsConnectOrSetupModalVisible] = useState(false);

  const handleConnectOrSetupModalOpen = useCallback(() => {
    setIsConnectOrSetupModalVisible(true);
  }, []);

  const handleConnectOrSetupModalClose = useCallback(async () => {
    setIsConnectOrSetupModalVisible(false);
  }, []);

  const { filterOptions } = usePhyhubDeviceFilters({
    environments: environmentsCollection,
    spaces: spacesResponse ? spacesResponse.spaces : [],
  });

  const { sortCriteriaOptions } = usePhyhubDeviceSortOptions();

  const { app } = useStore<
    RootState,
    {
      app: OrganisationApp | null;
    }
  >((state) => {
    const organisationApp =
      (state.organisationApps.data &&
        state.organisationApps.data[tenantId] &&
        state.organisationApps.data[tenantId][appId]) ||
      null;

    return {
      app: organisationApp,
    };
  });

  const { handleDeviceDisconnect } = usePhyhubDeviceDisconnectFlow({
    tenantId,
    installation: app,
  });

  const presetFilters = useMemo(
    () => ({
      installationIds: {
        op: PhyhubDeviceListParamsFiltersOperatorEnum.IN,
        val: [appId],
      },
    }),
    [appId],
  );

  const {
    searchPhrase,
    handleSetSearchPhrase,
    filters,
    handleFiltersChange,
  } = usePhyhubDevicesSearchAndFilters({ presetFilters });

  const [selectedDevices, setSelectedDevices] = useState<PhyhubDevice[]>([]);

  const handleSetSelectedDevices = useCallback((devices: PhyhubDevice[]) => {
    setSelectedDevices(devices);
  }, []);

  const handleResetSelectedDevices = useCallback(() => {
    setSelectedDevices([]);
  }, []);

  const { defaultDeviceActionItems } = usePhyhubDevicesDefaultDeviceActions({ tenantId });

  const {
    isMassActionsLoading,
    defaultDeviceMassActionItems,
  } = usePhyhubDevicesDefaultMassActions({
    tenantId,
    onResetSelectedDevices: handleResetSelectedDevices,
  });

  const deviceActionItems = useMemo<PhyhubDevicesSettingsAction[]>(
    () => [
      {
        icon: 'disconnect',
        title: t('disconnectDevice'),
        getKey: (device: PhyhubDevice) => `${device.id}_disconnect_device`,
        onClick: handleDeviceDisconnect,
      },
      ...defaultDeviceActionItems,
    ],
    [defaultDeviceActionItems, handleDeviceDisconnect, t],
  );

  const {
    defaultTableColumns,
    getTableRowSelection,
  } = usePhyhubDevicesDefaultTableColumns({
    deviceActionItems,
    massActions: {
      selectedDevices,
      deviceMassActionItems: defaultDeviceMassActionItems,
      onSetSelectedDevices: handleSetSelectedDevices,
    },
  });

  const tableColumns = useMemo<ColumnProps<PhyhubDevice>[]>(
    () => defaultTableColumns.filter(({ key }) => key !== 'installation'),
    [defaultTableColumns],
  );

  return (
    <>
      <Wrapper>
        <ConnectDeviceButton
          size="large"
          icon="api"
          onClick={handleConnectOrSetupModalOpen}
          data-testid="phyhub-devices-connect-device-button"
        >
          {t('connectDevice')}
        </ConnectDeviceButton>
      </Wrapper>
      <PhyhubDevicesContainer
        isTableLoading={isMassActionsLoading}
        searchPhrase={searchPhrase}
        tenantId={tenantId}
        filterOptions={filterOptions}
        filters={filters}
        getTableRowSelection={getTableRowSelection}
        overview={
          <PhyhubDevicesOverviewContainer
            searchPhrase={searchPhrase}
            tenantId={tenantId}
            filters={filters}
          />
        }
        sortCriteriaOptions={sortCriteriaOptions}
        tableColumns={tableColumns}
        onFiltersChange={handleFiltersChange}
        onSearch={handleSetSearchPhrase}
      />
      <ConnectSetupPhyhubDeviceModal
        {...props}
        isVisible={isConnectOrSetupModalVisible}
        onClose={handleConnectOrSetupModalClose}
        app={app}
      />
    </>
  );
};

const ConnectDeviceButton = styled(Button)`` as any;

const Wrapper = styled.div`
  position: relative;
  padding: 20px 40px;
  margin-bottom: -20px;
  display: flex;
  justify-content: flex-end;
`;

export default InstallationPhyhubDevicesContainer;
