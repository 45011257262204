import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { message } from 'antd';
import usePhyhubDevicesUpdate from '../../../../../store/hooks/phyhub-devices/use-phyhub-devices-update';
import { PhyhubDeviceDetailsFormValues } from './phyhub-device-details-screen-form/phyhub-device-details-screen-form.component';
import Message from '../../../../common/message';
import { PhyhubDevice } from '../../../../../services/phyhub/types/phyhub-device.interface';
import useEnvironments from '../../../../common/use-environments/use-environments';
import { useSpaces } from '../../../../common/use-spaces';
import {
  BackButton,
  BlockContainer,
  StateWrapper,
} from '../../../../common/phyhub-devices/phyhub-devices-common.components';
import Overlay from '../../../../common/overlay';
import { Spinner } from '../../../../common/spinner';
import ErrorView from '../../../../common/error-view/error-view.component';
import PhyhubDeviceDetailsScreenInstallationsContainer from './phyhub-device-details-screen-installations/phyhub-device-details-screen-installations.container';
import useGoBack from '../../../../common/use-go-back';
import PhyhubDeviceDetailsScreen from './phyhub-device-details-screen.component';

interface PhyhubDeviceDetailsScreenContainerProps {
  deviceItem: PhyhubDevice;
}

const PhyhubDeviceDetailsScreenContainer: FC<PhyhubDeviceDetailsScreenContainerProps> = (
  props,
) => {
  const { deviceItem } = props;

  const { t } = useTranslation();

  const {
    isError: isEnvironmentsError,
    isFetching: isEnvironmentsFetching,
    isLoading: isEnvironmentsLoading,
    isSuccess: isEnvironmentsSuccess,
    data: environmentsCollection,
  } = useEnvironments(deviceItem.tenantId);

  const {
    isError: isSpacesError,
    isFetching: isSpacesFetching,
    isLoading: isSpacesLoading,
    isSuccess: isSpacesSuccess,
    data: spacesResponse,
  } = useSpaces({ organisationId: deviceItem.tenantId });

  const { mutateAsync: phyhubDeviceUpdate } = usePhyhubDevicesUpdate();

  const handleFormSubmit = useCallback(
    async (values: PhyhubDeviceDetailsFormValues): Promise<void> => {
      try {
        await phyhubDeviceUpdate({
          tenantId: deviceItem.tenantId,
          deviceId: deviceItem.id,
          payload: {
            displayName: values.displayName,
            env: values.env,
            spaceId: values.spaceId || undefined,
            notes: values.notes || '',
            externalId: values.externalId || '',
            screen: values.screen || undefined,
          },
        });

        message.success(
          <Message content={t('phyhubDevices.details.message.editSuccess')} />,
        );
      } catch (error) {
        // TODO: Implement Phyhub errors parsing logic
        message.error(
          <Message content={t('phyhubDevices.details.message.editFailed')} />,
        );
      }
    },
    [t, phyhubDeviceUpdate, deviceItem],
  );

  const goBack = useGoBack();

  const handleGoBackClick = useCallback(() => {
    goBack(`/organisations/${deviceItem.tenantId}/devices/phyhub-devices`);
  }, [deviceItem, goBack]);

  if (
    isEnvironmentsFetching ||
    isEnvironmentsLoading ||
    isSpacesFetching ||
    isSpacesLoading
  ) {
    return (
      <StateWrapper>
        <Overlay>
          <Spinner />
        </Overlay>
      </StateWrapper>
    );
  }

  // TODO: Split empty state and error state is necessary
  if (
    isEnvironmentsError ||
    isSpacesError ||
    !isEnvironmentsSuccess ||
    !isSpacesSuccess ||
    !spacesResponse ||
    !environmentsCollection
  ) {
    return (
      <StateWrapper>
        <ErrorView />
      </StateWrapper>
    );
  }

  return (
    <>
      <BackButtonWrapper>
        <BackButton type="default" icon="arrow-left" onClick={handleGoBackClick}>
          {t('back')}
        </BackButton>
      </BackButtonWrapper>
      <PhyhubDeviceDetailsScreen
        deviceItem={deviceItem}
        environments={environmentsCollection}
        spaces={spacesResponse.spaces}
        onFormSubmit={handleFormSubmit}
      />
      <PhyhubDeviceDetailsScreenInstallationsContainer
        tenantId={deviceItem.tenantId}
        deviceInstallationIds={deviceItem.installationIds}
      />
    </>
  );
};

const BackButtonWrapper = styled(BlockContainer)`
  margin: 0;

  @media screen and (min-width: 992px) {
    margin: 30px 0 0;
  }
`;

export default PhyhubDeviceDetailsScreenContainer;
