import { PhyhubDeviceArchitectureEnum } from '../../services/phyhub/enums/phyhub-device-architecture.enum';
import { PhyhubDeviceStatusEnum } from '../../services/phyhub/enums/phyhub-device-status.enum';
import { PhyhubDeviceOsTypeEnum } from '../../services/phyhub/enums/phyhub-device-os-type.enum';
import { PhyhubDeviceTypeEnum } from '../../services/phyhub/enums/phyhub-device-type.enum';

export const PHYHUB_DEVICE_SCREEN_RESOLUTIONS = [
  '640x480',
  '800x600',
  '1024x768',
  '1440x900',
  '1920x1080',
  '1920x1440',
  '3840x2160',
] as const;
export const PHYHUB_DEVICE_SCREEN_SCALES = ['1.0', '1.5', '2.0', '0.75', '0.5'] as const;
export const PHYHUB_DEVICE_SCREEN_ORIENTATIONS = [
  'landscape',
  'portrait',
  'portrait-left',
  'landscape-inverted',
] as const;
export const PHYHUB_DEVICE_SCREEN_SOUND_OUTPUTS = [
  'default',
  'hdmi1',
  'hdmi2',
  'jack',
] as const;

export const PHYHUB_DEVICE_SCREEN_SOUND_INPUTS = ['default', 'external'] as const;

// TODO: Move constants to other directory if necessary

export const PHYHUB_DEVICE_ARCHITECTURE_NAMES_MAP: Record<
  PhyhubDeviceArchitectureEnum,
  string
> = {
  [PhyhubDeviceArchitectureEnum.ARM]: 'phyhubDeviceArchitectureOption.armv7',
};

export const PHYHUB_DEVICE_STATUS_TRANSLATION_MAP: Record<
  PhyhubDeviceStatusEnum,
  string
> = {
  [PhyhubDeviceStatusEnum.ONLINE]: 'phyhubDevices.status.online',
  [PhyhubDeviceStatusEnum.OK]: 'phyhubDevices.status.online',
  [PhyhubDeviceStatusEnum.FAILING]: 'phyhubDevices.status.failing',
  [PhyhubDeviceStatusEnum.OFFLINE]: 'phyhubDevices.status.offline',
};

export const PHYHUB_DEVICE_STATUS_FILTERS: PhyhubDeviceStatusEnum[] = [
  PhyhubDeviceStatusEnum.ONLINE,
  PhyhubDeviceStatusEnum.OFFLINE,
];

export const PHYHUB_DEVICE_OS_TYPE_TRANSLATION_MAP: Record<
  PhyhubDeviceOsTypeEnum,
  string
> = {
  [PhyhubDeviceOsTypeEnum.GRID_OS]: 'phyhubDevices.osType.gridOs',
  [PhyhubDeviceOsTypeEnum.PHY_OS]: 'phyhubDevices.osType.phyOs',
};

export const PHYHUB_DEVICE_SCREEN_ORIENTATION_TRANSLATION_MAP: Record<
  typeof PHYHUB_DEVICE_SCREEN_ORIENTATIONS[number],
  string
> = {
  landscape: 'phyhubDevices.screenOrientation.landscape',
  portrait: 'phyhubDevices.screenOrientation.portrait',
  'portrait-left': 'phyhubDevices.screenOrientation.portraitLeft',
  'landscape-inverted': 'phyhubDevices.screenOrientation.landscapeInverted',
};

export const PHYHUB_DEVICE_SCREEN_SOUND_OUTPUT_TRANSLATION_MAP: Record<
  typeof PHYHUB_DEVICE_SCREEN_SOUND_OUTPUTS[number],
  string
> = {
  default: 'phyhubDevices.soundOutput.default',
  hdmi1: 'phyhubDevices.soundOutput.hdmi1',
  hdmi2: 'phyhubDevices.soundOutput.hdmi2',
  jack: 'phyhubDevices.soundOutput.jack',
};

export const PHYHUB_DEVICE_SCREEN_SOUND_INPUT_TRANSLATION_MAP: Record<
  typeof PHYHUB_DEVICE_SCREEN_SOUND_INPUTS[number],
  string
> = {
  default: 'phyhubDevices.soundInput.default',
  external: 'phyhubDevices.soundInput.external',
};

export const PHYHUB_DEVICE_TYPE_TRANSLATION_MAP: Record<PhyhubDeviceTypeEnum, string> = {
  [PhyhubDeviceTypeEnum.GENERIC_BROWSER]: 'phyhubDevices.type.genericBrowser',
  [PhyhubDeviceTypeEnum.GRID_OS]: 'phyhubDevices.type.gridOs',
  [PhyhubDeviceTypeEnum.PHY_OS]: 'phyhubDevices.type.phyOs',
};

export const PHYHUB_DEVICES_OVERVIEW_STATUSES: PhyhubDeviceStatusEnum[] = [
  PhyhubDeviceStatusEnum.ONLINE,
  PhyhubDeviceStatusEnum.OFFLINE,
];
