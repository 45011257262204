import React from 'react';
import styled from '@emotion/styled';
import { Icon } from 'antd';
import usePhyhubDeviceStatusColor from '../phyhub-device-status/hooks/use-phyhub-device-status-color';
import { PhyhubDeviceStatusEnum } from '../../../../services/phyhub/enums/phyhub-device-status.enum';
import { PhyhubDeviceStatusColorEnum } from '../phyhub-device-status/phyhub-device-status';

interface PhyhubDeviceCardProps {
  deviceDisplayName: string;
  deviceScreenshotUrl?: string | null;
  deviceStatus: PhyhubDeviceStatusEnum;
}

const PhyhubDeviceCard: React.FC<PhyhubDeviceCardProps> = (props) => {
  const { deviceDisplayName, deviceScreenshotUrl, deviceStatus } = props;

  const deviceStatusColor = usePhyhubDeviceStatusColor(deviceStatus);

  return (
    <CoverContainer>
      {deviceScreenshotUrl ? (
        <ScreenshotImage alt={deviceDisplayName} src={deviceScreenshotUrl} />
      ) : (
        <PlaceholderContainer color={deviceStatusColor}>
          <PlaceholderIcon type="picture" />
        </PlaceholderContainer>
      )}
    </CoverContainer>
  );
};

const CoverContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;

const ScreenshotImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const PlaceholderContainer = styled.div<{ color: PhyhubDeviceStatusColorEnum }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  opacity: 0.2;
  background-color: ${({ color }) => color};
`;

const PlaceholderIcon = styled(Icon)`
  font-size: 48px;
  color: #bfbfbf;
`;

export default PhyhubDeviceCard;
